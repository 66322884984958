<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    :class="'w-6 h-6 ' + customClass"
    :stroke-width="customStrokeWidth"
    :stroke="customStroke"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M6 18 18 6M6 6l12 12"
    />
  </svg>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: '',
    },
    customStrokeWidth: {
      type: Number,
      default: 1.5,
    },
    customStroke: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>
