<script setup>
import route from 'ziggy-js'
import { ref } from 'vue'
import DropdownItem from './Partials/DropdownItem.vue'
import IconContact from '../../../images/icon-contact.png'
import IconZalo from '../../../images/icon-zalo.png'
import Facebook from '../../../images/facebook.png'

const props = defineProps(['list', 'categories'])

const listCategory = ref(props.categories ?? [])

const openDropDownCategory = ref(false)
const openDropDownContact = ref(false)

const toggleCategory = () => {
  openDropDownContact.value = false
  openDropDownCategory.value = true
}

const toggleContact = () => {
  openDropDownCategory.value = false
  openDropDownContact.value = true
}

const close = () => {
  openDropDownContact.value = false
  openDropDownCategory.value = false
}

const isImagePath = (path) => {
  const imagePathPrefix = 'assets/images'
  const check = path.includes(imagePathPrefix)
  if (check) {
    return path
  }
  return 'storage/' + path
}

const listItemContact = [
  {
    name_contact: 'FANPAGE',
    link: 'https://www.facebook.com/profile.php?id=100083102053812',
    image: Facebook,
  },
  {
    name_contact: 'FACEBOOK',
    link: 'https://www.facebook.com/lukaku.romelu.587606',
    image: Facebook,
  },
  {
    name_contact: 'ZALO',
    link: 'https://zalo.me/',
    image: IconZalo,
  },
]

const handleSearch = async (e) => {
  if (!e) {
    listCategory.value = props.categories
    return
  }

  const category_name = e
  // Get the CSRF token from the meta tag (commonly used in Laravel applications)
  const token = document
    .querySelector('input[name="_token"]')
    .getAttribute('value')

  try {
    const response = await fetch(route('category.search'), {
      method: 'POST',
      body: JSON.stringify({ category_name }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': token,
      },
    })

    if (!response.ok) {
      return
    }

    listCategory.value = await response.json()
  } catch (error) {
    return
  }
}
</script>

<template>
  <a
    :target="list.istaget ? 'backlink' : ''"
    :href="list.url"
    v-if="!list.is_dropdown"
    class="pb-7 text-base sm:text-lg lg:text-xl font-normal font-sans flex items-center gap-2"
  >
    <component
      :is="list.icon"
      :class="[' text-[#FFD733]', list.size == 7 ? 'h-7 w-7' : 'h-5 w-5']"
      v-if="list.icon && typeof list.icon === 'function'"
    />
    <img
      :src="list.icon"
      :class="[list.size == 7 ? 'h-7 w-7' : 'h-5 w-5']"
      v-else-if="typeof list.icon === 'string'"
    />
    {{ list.page }}
  </a>

  <!-- show dropdown -->
  <div v-else @mouseleave="close()">
    <div
      class="relative pb-7 flex items-center cursor-pointer gap-2 text-[#FFD733] focus:text-[#2E86C1] hover:text-[#2E86C1] text-base sm:text-lg lg:text-xl font-semibold"
      @mouseover="list.page == 'Danh Mục' ? toggleCategory() : toggleContact()"
    >
      <component
        :is="list.icon"
        :class="[' text-[#FFD733]', list.size == 7 ? 'h-7 w-7' : 'h-5 w-5']"
        v-if="list.icon && typeof list.icon === 'function'"
      />
      <img
        :src="list.icon"
        :class="[list.size == 7 ? 'h-7 w-7' : 'h-5 w-5']"
        v-else-if="typeof list.icon === 'string'"
      />
      {{ list.page }}
    </div>

    <div
      v-if="list.page == 'Liên Hệ' && openDropDownContact"
      class="absolute left-0 z-[9] w-full"
    >
      <section class="contact bg-[#1F618D]">
        <div class="flex justify-center py-5">
          <h2
            class="text-white text-center font-bold text-2xl font-['Inter'] capitalize"
          >
            {{ list.page }}
          </h2>
        </div>
      </section>
      <DropdownItem>
        <div
          class="flex gap-2 items-center"
          v-for="(contact, index) in listItemContact"
          :key="index"
        >
          <a :href="contact.link" class="w-1/4" target="_blank">
            <img
              :src="contact.image"
              class="aspect-video text- rounded-2xl h-auto sm:h-12 w-auto sm:w-12"
              :alt="list.page"
            />
          </a>
          <div class="px-2">
            <a :href="contact.link" class="block" target="_blank">
              <h5
                class="font-normal mt-1 mb-3 text-start text-white text-base sm:text-xl capitalize font-['Inder']"
                style="
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                "
              >
                {{ contact['name_contact'] }}
              </h5>
            </a>
          </div>
        </div>
      </DropdownItem>
    </div>

    <!-- show dropdow categories -->
    <div
      v-if="list.page == 'Danh Mục' && openDropDownCategory"
      class="absolute left-0 z-[9] w-full"
    >
      <section class="category-search bg-[#1F618D]">
        <div class="flex justify-center py-5">
          <input-search
            name="category_name"
            @input="handleSearch($event.target.value)"
            :width="1000"
            title="Tìm kiếm danh mục, tên game,..."
            type="button"
            background="#D9D9D9"
          />
          <slot></slot>
        </div>
      </section>
      <DropdownItem>
        <div
          class="grid grid-cols-3 gap-2 items-center"
          v-for="category in listCategory"
        >
          <a :href="route('product', category['id'])" class="col-span-1">
            <img
              :src="'/' + isImagePath(category.hinh_loai)"
              class="aspect-video text- rounded-2xl h-auto sm:h-14 w-auto sm:w-14"
              :alt="category['ten_loai']"
            />
          </a>
          <div class="px-2 col-span-2 items-center">
            <a
              :href="route('product', category['id'])"
              class="font-normal mt-1 mb-3 text-start text-white text-base sm:text-xl capitalize font-['Inder']"
              style="
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              "
            >
              {{ category['ten_loai'] }}
            </a>
          </div>
        </div>
      </DropdownItem>
    </div>
  </div>
</template>
