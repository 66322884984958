<template>
  <div
    tabindex="0"
    :class="[
      'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 m-auto w-full md:inset-0 h-modal md:h-full justify-center items-center flex z-[99]',
      show ? 'block' : 'hidden',
    ]"
    @click="closeModal"
  >
    <div
      :class="['relative p-auto sm:p-4 h-auto sm:h-full w-full sm:max-w-7xl ']"
      :style="`top:${topPosition}%`"
    >
      <div class="relative rounded-lg shadow">
        <div
          class="p-4 rounded-t flex justify-between items-center dark:border-gray-600"
        >
          <div>
            <h3 class="capitalize font-serif text-xl" v-if="title !== ''">
              {{ title }}
            </h3>
          </div>
          <button
            aria-label="close"
            id="close_modal"
            type="button"
            class="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center"
            @click="handleClose"
          >
            <svg
              class="w-8 h-8 bg-white rounded-full"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div class="px-2 sm:p-6 rounded-b">
          <div class="flex flex-col gap-3">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Semi-transparent background -->
  <template v-if="show">
    <div class="bg-black/50 h-full left-0 top-0 w-full fixed z-[5]"></div>
  </template>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    default: () => {},
  },
  topPosition: {
    type: Number,
    default: 20,
  },
  title: String,
})
props.topPosition = props.topPosition === null ? 20 : props.topPosition

const closeModal = (event) => {
  if (
    event.target === event.currentTarget ||
    event.target.closest('#close_modal')
  ) {
    props.onClose()
  }
}

const handleClose = () => {
  props.onClose()
}
</script>
