<script setup>
import LayoutProfile from '@/Layouts/LayoutProfile.vue'

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}
</script>
<template>
  <LayoutProfile :title_page="'Lịch sử nạp'">
    <div class="overflow-x-auto p-0 min-h-[100px]">
      <table class="table table-bordered w-full bg-white">
        <thead class="title-table">
          <tr class="tab-header gap-2 border border-black">
            <th
              scope="col "
              class="w-1/4 whitespace-nowrap text-xl font-[Inder] font-medium text-black border-black border-e p-4 text-center"
            >
              Thời Gian
            </th>
            <th
              scope="col"
              class="w-1/4 whitespace-nowrap text-xl font-[Inder] font-medium text-black border-black border-e p-4"
            >
              Dịch Vụ
            </th>
            <th
              scope="col"
              class="w-1/4 whitespace-nowrap text-xl font-[Inder] font-medium text-black border-black border-e p-4 text-center"
            >
              Số Tiền Nạp Vào
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="bill in payments.data"
            :key="bill.billID"
            class="border border-black"
          >
            <td
              scope="row"
              class="border-black border-e p-2 text-black text-center text-lg font-normal"
            >
              {{ formatDate(bill.created_at) }}
            </td>
            <td
              scope="row"
              class="border-e-2 px-2 text-center text-black text-lg font-normal capitalize"
            >
              {{ bill.menh_gia ? 'Thẻ cào' : 'Chuyển khoản' }}
            </td>
            <td
              scope="row"
              class="border-black border-e-2 px-2 text-black text-lg font-normal text-center"
            >
              {{ formatCurrency(bill.price ?? bill.menh_gia) }}
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>

      <pagination-component
        :pagination="payments"
        btn_color="text-[#000102] !w-7 font-bold"
      />
    </div>
  </LayoutProfile>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: ['payments'],
  methods: {
    formatDate(timestamp) {
      return format(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss')
    },
  },
}
</script>
