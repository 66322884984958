<script setup>
import route from 'ziggy-js'
import Button from '@/components/Button.vue'

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}

const isImagePath = (path) => {
  const imagePathPrefix = 'assets/images'
  const check = path.includes(imagePathPrefix)
  if (check) {
    return path
  }
  const imagePathPrefixStorage = 'public/'
  const checkStorage = path.includes(imagePathPrefixStorage)
  if (checkStorage) {
    return '/storage/' + path.replace(/^public\//, '')
  }

  return 'storage/' + path
}
</script>

<template>
  <section class="">
    <div
      class="grid gap-10 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 justify-center min-h-200 m-auto"
    >
      <a
        v-for="sanphamdm in sanphamdms.data"
        :key="sanphamdm.id"
        class="rounded-lg justify-center border bg-[#FAFAF5] border-[#979797]"
        :href="`${route('product.details', {
          maloai: sanphamdm.ma_loai,
          slug_product: sanphamdm.slug,
        })}`"
      >
        <div
          class="flex product-transition flex-col gap-3 overflow-hidden relative bg-black border border-white rounded"
        >
          <a
            :href="`${route('product.details', {
              maloai: sanphamdm.ma_loai,
              slug_product: sanphamdm.slug,
            })}`"
            class="h-[80%]"
            :value="sanphamdm.slug"
          >
            <img
              class="h-64 aspect-video w-full border border-b-gray-400 rounded-t-md"
              :src="'/' + isImagePath(sanphamdm.hinh)"
              alt="Product Image"
            />
          </a>

          <div class="h-[20%] text-left flex flex-col px-4 mb-7">
            <div>
              <span
                class="capitalize text-[#7FB3D5] font-['Inter'] text-lg font-bold"
              >
                {{ sanphamdm.category.ten_loai }}
              </span>
            </div>
            <h5 class="h-14" :title="sanphamdm.productTitle">
              <span
                class="overflow-hidden capitalize text-white font-['Inter'] text-lg font-semibold"
                style="
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                "
              >
                {{ sanphamdm.productTitle }}
              </span>
            </h5>

            <div class="">
              <span class="text-[#D4AC0D] font-['Inter'] text-lg font-bold">
                {{ formatCurrency(sanphamdm.giasp) }}
              </span>
            </div>
          </div>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  props: ['sanphamdms'],
  components: {
    Button,
  },
}
</script>
