<script setup>
const props = defineProps({
  background: {
    type: String,
    default: '#154360',
  },
  column: {
    type: Number,
    default: 5,
  },
})
const gridColumn = () => {
  switch (props.column) {
    case '3':
      return 'grid grid-cols-2 sm:grid-cols-3 '
    default:
      return 'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5'
  }
}

const getColor = () => {
  console.log(props.background)

  return props.background ?? '#154360'
}
</script>
<template>
  <section
    :class="`bg-[${getColor()}] min-h-[250px] py-6 mb-20 max-h-[350px] sm:max-h-[500px] overflow-y-auto`"
    :style="`background:${getColor()}`"
  >
    <div
      :class="[
        'gap-x-1 gap-y-5 sm:gap-x-5 sm:gap-y-8 mx-auto max-w-7xl sm:max-w-8xl xl:max-w-8xl px-2 sm:px-5',
        gridColumn(),
      ]"
    >
      <slot></slot>
    </div>
  </section>
</template>
