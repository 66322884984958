<script setup>
import route from 'ziggy-js'
import Xcircle from '../../components/icon/Xcircle.vue'
</script>

<template>
  <div class="">
    <template v-for="cart in cart" :key="cart.id">
      <div
        class="grid grid-cols-1 sm:grid-cols-4 self-center flex-wrap sm:flex-nowrap text-center gap-5 mx-5 sm:mx-10 my-5 sm:my-8 border-b-2 border-[#ededed] pb-3 sm:pb-5"
      >
        <div class="max-h-44 sm:h-[189px]">
          <img
            :src="isImagePath(cart.iamges)"
            alt="Product Image"
            class="w-full h-full"
          />
        </div>

        <div
          class="grid grid-cols-1 sm:grid-cols-5 items-center gap-2 col-span-3"
        >
          <div class="w-full sm:w-auto col-span-2">
            <p
              class="text-black text-lg font-normal min-h-14 font-['Inder'] capitalize text-left"
            >
              {{ cart.name }}
            </p>
          </div>

          <div class="col-span-3 grid grid-cols-3">
            <div class="text- text-left text-xl font-normal font-['Inder']">
              <span class="ms-2 bg-[#D9D9D9] py-0.5 px-2">{{
                cart.quantity
              }}</span>
            </div>
            <div class="text-left">
              <span
                class="ms-2 text-[#D4AC0D] font-normal text-xl font-['Inder']"
                >{{ formatCurrency(cart.price) }}</span
              >
            </div>
            <div class="text-center ms-auto">
              <form
                :action="
                  route('cart.remove', {
                    product_type: 'product',
                    productId: cart.id,
                  })
                "
                method="POST"
              >
                <slot></slot>
                <button type="submit" class="">
                  <Xcircle
                    class="bg-red-500 rounded-full p-1 text-base text-white"
                  />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['cart'],
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(value)
    },
    isImagePath(path) {
      const imagePathPrefix = 'assets/images'
      const check = path.includes(imagePathPrefix)
      if (check) {
        return path
      }
      const imagePathPrefixStorage = 'public/'
      const checkStorage = path.includes(imagePathPrefixStorage)
      if (checkStorage) {
        return '/storage/' + path.replace(/^public\//, '')
      }
      return '/storage/' + path
    },
  },
}
</script>
