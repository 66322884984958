<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: 'Tìm tên danh mục ...',
  },
  type: {
    type: String,
    default: 'button',
  },
  width: {
    type: Number,
    default: 500,
  },
  background: {
    type: String,
    default: '#FFD733',
  },
})

// Use computed property to manage dynamic width
const widthValue = computed(() => `${props.width}px`)
</script>

<style>
/* Apply dynamic width for screens larger than 640px */
@media (min-width: 640px) {
  .dynamic-width {
    width: var(--widthValue);
  }
}
</style>

<template>
  <div
    placeholder="tên danh mục ..."
    class="flex items-center self-center justify-center"
  >
    <!---->
    <div
      :style="{ '--widthValue': widthValue }"
      class="flex relative w-full dynamic-width"
    >
      <input
        :placeholder="title"
        :class="[
          'flex items-center self-center justify-center bg-none border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full placeholder-black/80 placeholder:text-xl placeholder:font-bold  px-8 py-4 font-normal font-[Inter] pl-10 text-xl',
          `bg-[${background}]`,
        ]"
        :style="`background: ${background};`"
        :name="name"
        type="text"
      />
      <div
        class="w-10 flex absolute inset-y-0 right-8 z-[5] items-center pl-3 cursor-pointer"
      >
        <button
          :type="type"
          size="sm"
          class="bottom-3 cursor-pointer border border-black/85 rounded-full p-2"
          id="search_product"
          aria-label="search product"
        >
          <svg
            aria-hidden="true"
            class="z-999 w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
