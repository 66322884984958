<script setup>
import { ref } from 'vue'
import route from 'ziggy-js'
import ModalComponents from '../../components/ModalComponents.vue'

const showModal = ref(false)

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}

// Define props
const props = defineProps({
  product: Object,
  list_images: Array,
})

// Initialize reactive state
const currentImageIndex = ref(props.list_images.indexOf(props.product.hinh))
const currentImage = ref(props.product.hinh)
const quantity = ref(1)

// Function to get image path
const getAbsolutePath = (index) => {
  return `${props.list_images[index]['path']}`
}

// Update current image based on index
const updateCurrentImage = (index) => {
  currentImage.value = getAbsolutePath(index)
}

// Methods for navigating images
const nextImage = () => {
  currentImageIndex.value =
    (currentImageIndex.value + 1) % props.list_images.length
  updateCurrentImage(currentImageIndex.value)
}

const previousImage = () => {
  currentImageIndex.value =
    (currentImageIndex.value - 1 + props.list_images.length) %
    props.list_images.length
  updateCurrentImage(currentImageIndex.value)
}

// Increment and decrement quantity
const increment = () => {
  quantity.value++
}

const decrement = () => {
  if (quantity.value > 1) {
    quantity.value--
  }
}

// Handle quantity change event
const handleQuantityChange = (event) => {
  const newQuantity = Math.max(1, parseInt(event.target.value, 10))
  quantity.value = newQuantity
}

const updateMainImage = (path) => {
  currentImage.value = path
  showModal.value = true
}

const isImagePath = (path) => {
  const imagePathPrefix = 'assets/images'
  const check = path.includes(imagePathPrefix)
  if (check) {
    return path
  }
  const imagePathPrefixStorage = 'public/'
  const checkStorage = path.includes(imagePathPrefixStorage)
  if (checkStorage) {
    return 'storage/' + path.replace(/^public\//, '')
  }

  return 'storage/' + path
}
</script>

<template>
  <section class="flex flex-col gap-6 sm:gap-12">
    <div class="flex flex-col gap-4">
      <div class="grid grid-cols-1 gap-0 sm:gap-8 mb-8">
        <div class="mb-6 sm:mb-0 relative">
          <img
            id="current-slide"
            class="rounded !max-h-[450px] object-fill aspect-video w-full"
            :src="'/' + isImagePath(product.hinh)"
            alt="Image main"
            style="max-height: calc(100vh - 220px)"
          />
        </div>
        <div
          class="text-start text-[#FDF8F899] text-lg sm:text-2xl whitespace-pre-wrap break-all font-normal font-['Inter'] capitalize mb-4 sm:mb-6"
        >
          Home / {{ product.category.ten_loai }} / {{ product.productTitle }}
        </div>
        <!-- modal image -->
        <ModalComponents :show="showModal" @close="showModal = false">
          <div class="flex flex-wrap sm:flex-nowrap justify-center gap-3">
            <div class="flex flex-col justify-center">
              <svg
                @click="previousImage"
                class="bg-[#188feb] h-8 w-8 cursor-pointer text-white rounded-full"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </div>

            <img
              id="current-slide"
              class="rounded-2 object-fill aspect-video min-h-[200px] sm:h-full w-full"
              :src="'/' + isImagePath(currentImage)"
              alt="Image main"
              style="max-height: calc(100vh - 220px)"
            />
            <div class="flex flex-col justify-center">
              <svg
                @click="nextImage"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="bg-[#188feb] text-white rounded-full h-8 w-8 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                />
              </svg>
            </div>
          </div>
        </ModalComponents>

        <article class="p-4">
          <div class="container grid grid-cols-1 sm:grid-cols-2 gap-2 h-full">
            <div class="flex flex-col gap-4">
              <div>
                <h2
                  class="flex justify-start text-[#DF0606] text-lg sm:text-2xl font-extrabold font-['Inika'] sm:font-normal"
                >
                  CHI TIẾT TÀI KHOẢN
                </h2>
              </div>
              <div class="details p-2">
                <ul class="list-unstyled" id="detail_product">
                  <p
                    v-html="product.mo_ta"
                    class="text-base text-white"
                    id="description"
                  ></p>
                </ul>
                <div
                  class="warn"
                  style="font-size: 20px; color: rgb(53, 152, 219)"
                ></div>
                <div></div>
              </div>
            </div>

            <div>
              <div class="flex">
                <div
                  class="text text-left text-white text-lg sm:text-2xl whitespace-pre-wrap break-all font-bold font-['Inter'] capitalize"
                >
                  {{ product.productTitle }}
                </div>
              </div>
              <div>
                <div
                  class="font-medium font-['Inter'] text-lg sm:text-2xl text-left p-3 rounded-2 text-[#D4AC0D]"
                >
                  {{ formatCurrency(product.giasp) }}
                </div>
              </div>
              <div class="justify-center gap-2 mt-auto">
                <div class="flex flex-col gap-3">
                  <div class="gap-2 flex items-center justify-start">
                    <div
                      class="flex items-center border bg-[#3011F0] rounded-3xl p-2"
                    >
                      <button
                        @click="decrement"
                        class="text-white bg-[#2196f3] justify-center flex font-bold w-7 h-7 rounded-full"
                      >
                        -
                      </button>
                      <input
                        type="number"
                        class="text-center w-16 border-0 bg-[#3011F0] text-white"
                        :value="quantity"
                        min="1"
                        @input="handleQuantityChange($event)"
                      />
                      <button
                        @click="increment"
                        class="text-white bg-[#2196f3] justify-center flex font-bold w-7 h-7 rounded-full"
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div
                    class="mt-5 flex flex-wrap sm:flex-nowrap justify-start items-center w-full gap-3 sm:gap-6"
                  >
                    <form
                      class="w-full sm:w-1/2"
                      :action="route('cart.add', product.id)"
                      method="POST"
                    >
                      <slot></slot>
                      <input type="hidden" :value="quantity" name="quantity" />
                      <input type="hidden" value="payment" name="payment" />
                      <button
                        class="bg-[#F91106] text-white px-5 w-full py-3 font-bold text-xl font-['Inter'] rounded-s-lg rounded-e-lg"
                      >
                        Mua Ngay
                      </button>
                    </form>
                    <form
                      class="w-full sm:w-1/2"
                      :action="route('cart.add', product.id)"
                      method="POST"
                    >
                      <slot></slot>
                      <input type="hidden" :value="quantity" name="quantity" />
                      <button
                        class="text-white px-5 w-full py-3 font-bold text-xl font-['Inter'] bg-[#06A821] rounded-s-lg rounded-e-lg"
                        type="submit"
                      >
                        Thêm giỏ hàng
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="bg-[#D9D9D9]">
      <div class="border border-[#D9D9D9] col-span-2 mb-6 sm:mb-0">
        <div
          class="grid col-span-2 sm:grid-cols-4 gap-4 sm:gap-7 px-4 sm:px-9 md:px-9 py-6 md:py-10"
        >
          <img
            id="ms-1"
            :src="'/' + isImagePath(product.hinh)"
            alt="Image 0"
            @click="updateMainImage(product.hinh)"
            class="w-full aspect-video border border-black"
          />
          <img
            v-for="(image, index) in list_images"
            :key="index"
            :src="'/' + isImagePath(image.path)"
            :alt="'Image' + (index + 1)"
            @click="updateMainImage(image.path)"
            class="w-full aspect-video border border-black"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style>
@media (max-width: 640px) {
  #detail_product * {
    font-size: 18px;
    line-height: inherit;
  }
}

@media (min-width: 640px) {
  #detail_product * {
    font-size: 25px;
    line-height: inherit;
  }
}
</style>
