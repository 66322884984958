<script setup>
import route from 'ziggy-js'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import DropdownItem from '../Menu/Partials/DropdownItem.vue'

const props = defineProps(['categories'])

const listCategory = ref([])

const openDropDownCategory = ref(false)

const toggle = () => {
  if (listCategory.value.length > 0) {
    openDropDownCategory.value = true
  }
}

const handleClickOutside = (event) => {
  const dropdown = document.querySelector('.dropdown-section')
  if (dropdown && !dropdown.contains(event.target)) {
    openDropDownCategory.value = false
  }
}

const isImagePath = (path) => {
  const imagePathPrefix = 'assets/images'
  const check = path.includes(imagePathPrefix)
  if (check) {
    return path
  }
  return 'storage/' + path
}

const handleSearch = async (e) => {
  if (!e) {
    listCategory.value = []
    openDropDownCategory.value = false
    return
  }

  const category_name = e
  // Get the CSRF token from the meta tag (commonly used in Laravel applications)
  const token = document
    .querySelector('input[name="_token"]')
    .getAttribute('value')

  try {
    const response = await fetch(route('category.search'), {
      method: 'POST',
      body: JSON.stringify({ category_name }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': token,
      },
    })

    if (!response.ok) {
      return
    }

    listCategory.value = await response.json()

    if (listCategory.value.length <= 0) {
      openDropDownCategory.value = false
    } else {
      openDropDownCategory.value = true
    }
  } catch (error) {
    return
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<template>
  <section class="dropdown-section absolute z-[1] top-0" @click="toggle()">
    <div class="py-5">
      <input-search
        name="category_name"
        @input="handleSearch($event.target.value)"
        :width="700"
        title="Tìm danh mục..."
        type="button"
        background="#FFD733"
      />
      <slot></slot>
    </div>

    <!-- show dropdow categories -->
    <div v-if="openDropDownCategory" class="absolute opacity-100 w-full">
      <DropdownItem background="#2A3A48" column="3">
        <div class="flex gap-2 items-center" v-for="category in listCategory">
          <a
            :href="route('product', category['id'])"
            class="w-[22%] sm:w-[15%]"
          >
            <img
              :src="'/' + isImagePath(category.hinh_loai)"
              class="aspect-video text- rounded-2xl h-8 w-8"
              :alt="category['ten_loai']"
            />
          </a>
          <div class="px-2 col-span-2 items-center w-3/4">
            <a
              :href="route('product', category['id'])"
              class="font-normal mt-1 mb-3 text-start text-white text-base sm:text-base capitalize font-['Inder']"
              style="
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              "
            >
              {{ category['ten_loai'] }}
            </a>
          </div>
        </div>
      </DropdownItem>
    </div>
  </section>
</template>
