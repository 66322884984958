<script setup>
import route from 'ziggy-js'
import LayoutProfile from '../../Layouts/LayoutProfile.vue'
</script>
<template>
  <LayoutProfile>
    <form :action="route('profile.update')" method="post" class="mb-3">
      <slot></slot>
      <div class="flex flex-col gap-5">
        <div class="mb-3">
          <label
            for="ten_hien_thi"
            class="block text-2xl font-normal text-white"
            >Tên hiển thị:</label
          >
          <input
            type="text"
            id="ten_hien_thi"
            name="ten_hien_thi"
            :value="user.ten_hien_thi"
            class="mt-1 p-3 text-xl font-normal border border-gray-700 w-full text-black"
          />
        </div>
        <div class="mb-3">
          <label for="email" class="block text-2xl font-normal text-white"
            >Email:</label
          >
          <input
            type="email"
            id="email"
            name="email"
            :value="user.email"
            class="mt-1 p-3 text-xl font-normal border border-gray-700 w-full text-black"
          />
        </div>
      </div>
      <div class="flex justify-center">
        <button
          type="submit"
          class="w-60 bg-blue-500 text-white p-3 rounded mt-2"
        >
          Cập nhật
        </button>
      </div>
    </form>
  </LayoutProfile>
</template>

<script>
export default {
  props: ['user'],
}
</script>
