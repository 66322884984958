<script setup>
import { ref } from 'vue'

import route from 'ziggy-js'

const profileInfo = ref([
  {
    lable: 'Thông tin chung',
    url: route('profile'),
  },
  {
    lable: 'Lịch sử đơn hàng',
    url: route('profile.historybill'),
  },
  {
    lable: 'Đổi mật khẩu',
    url: route('accoutnChange'),
  },
  {
    lable: 'Lịch sử nạp',
    url: route('profile.historyPayment'),
  },
])

const props = defineProps(['title_page'])

const isActive = (url) => {
  const currentPath = window.location.pathname
  const normalizedCurrentPath = currentPath.endsWith('/')
    ? currentPath.slice(0, -1)
    : currentPath
  const normalizedUrlPath = new URL(url).pathname.endsWith('/')
    ? new URL(url).pathname.slice(0, -1)
    : new URL(url).pathname

  return normalizedCurrentPath === normalizedUrlPath
}
</script>
<template>
  <section class="">
    <div class="mx-auto max-w-7xl gap-10 my-12 min-h-[400px]">
      <div class="">
        <div class="">
          <div class="grid grid-cols-4 w-full">
            <a
              v-for="(profileInfo, index) in profileInfo"
              :key="index"
              :href="profileInfo.url"
              :class="[
                '  focus:outline-none text-black text-lg sm:text-xl text-center font-[Kadwa] font-bold   focus:ring-2 px-4 py-6  border-b-2 border-gray-300',
                isActive(profileInfo.url) ? 'bg-[#33DAFF] ' : ' bg-[#D9D9D9] ',
              ]"
            >
              {{ profileInfo.lable }}
            </a>
          </div>

          <div class="w-full bg-[#277DAEE6]">
            <div
              :class="[
                'px-5 sm:px-40 py-3 sm:py-10 min-h-96',
                'ring-white/60  focus:outline-none focus:ring-2',
              ]"
            >
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
