<script setup>
import route from 'ziggy-js'
import { ListBulletIcon, ShoppingCartIcon } from '@heroicons/vue/24/solid'
import { ref } from 'vue'
import Drawer from './Drawer.vue'
import DropDownUser from './DropDownUser.vue'
import Logo from '../../images/logo.png'
import facebook from '../../images/facebook.png'
import Policy from '../../images/Policy.png'
import Wallet from './icon/wallet.svg'
import InformationUser from './icon/InformationUser.svg'
import History from './icon/History.svg'
import Password from './icon/Password.svg'
import Logout from './icon/Logout.svg'
import CartIcon from './icon/Cart.png'
import ModalComponents from './ModalComponents.vue'
import Login from '../Pages/Auth/Login.vue'
import Resgiter from '../Pages/Auth/Resgiter.vue'
import ShowListMenu from './Menu/ShowListMenu.vue'

const props = defineProps(['user', 'categories'])

const showLogin = ref(false)
const showRegister = ref(false)

const menu = [
  {
    url: route('category'),
    page: 'Danh Mục',
    istaget: false,
    icon: ListBulletIcon,
    size: 7,
    is_dropdown: true,
  },
  {
    url: route('recharge'),
    page: 'Nạp Tiền',
    istaget: false,
    icon: '/assets/img/icon/coin.png',
    size: 5,
    is_dropdown: false,
  },
  {
    url: 'https://www.facebook.com/profile.php?id=100083102053812',
    page: 'Liên Hệ',
    istaget: true,
    icon: facebook,
    size: 5,
    is_dropdown: true,
  },
  {
    url: route('policy'),
    page: 'Chính sách bảo hành',
    istaget: false,
    icon: Policy,
    size: 7,
    is_dropdown: false,
  },
]

const isActive = (url) => {
  const currentPath = window.location.pathname
  const normalizedCurrentPath = currentPath.endsWith('/')
    ? currentPath.slice(0, -1)
    : currentPath
  const normalizedUrlPath = new URL(url).pathname.endsWith('/')
    ? new URL(url).pathname.slice(0, -1)
    : new URL(url).pathname

  return normalizedCurrentPath === normalizedUrlPath
}

const toggleLogin = () => {
  showLogin.value = true
  showRegister.value = false
}

const close = () => {
  showLogin.value = false
  showRegister.value = false
}

const toggleRegister = () => {
  showLogin.value = false
  showRegister.value = true
}

const links = [
  {
    href: route('profile'),
    label: 'Thông tin người dùng',
    icon: InformationUser,
  },
  {
    href: route('profile.historybill'),
    label: 'Lịch sử đơn hàng',
    icon: History,
  },
  { href: route('profile.ChangePass'), label: 'Đổi mật khẩu', icon: Password },
  { href: route('logout_client'), label: 'Đăng xuất', icon: Logout },
]

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}

const isShowModal = ref(false)

const handleOpen = () => {
  isShowModal.value = true
}

const onClose = () => {
  isShowModal.value = false
}
</script>

<template>
  <header
    class="bg-[#1C2833] sticky top-0 flex items-center md:items-end pb-2 justify-between gap-4 border-b border-gray-700 px-2 pt-4 sm:px-6 md:px-8 lg:px-20 z-[6]"
  >
    <button
      class="block md:hidden px-2 py-1 text-white"
      type="button"
      @click="handleOpen"
      aria-label="List Bullet Icon"
    >
      <ListBulletIcon class="h-5 w-5" />
    </button>
    <Drawer :isShowModal="isShowModal" :onClose="onClose">
      <div class="flex flex-col gap-2">
        <MenuItem
          :class="{
            'text-lg px-4': true,
            'text-white': !isActive(list.url),
            'text-blue-300': isActive(list.url),
          }"
          v-for="list in menu"
          :key="list.url"
        >
          <a :href="list.url" class="text-xl font-bold whitespace-normal">
            {{ list.page }}
          </a>
        </MenuItem>
      </div>
    </Drawer>
    <div class="flex gap-3 sm:gap-10 items-end">
      <div>
        <a href="/">
          <img
            alt="NGHIENGACHA"
            :src="Logo"
            class="ms-0 md:ms-5 h-auto lg:h-24 w-auto aspect-video"
          />
        </a>
      </div>

      <div class="flex items-center justify-center gap-5">
        <ul class="hidden items-center gap-1 md:flex">
          <li
            :class="{
              'text-lg px-3': true,
              'text-[#FFD733] ': !isActive(list.url),
              'text-[#2E86C1] underline': isActive(list.url),
            }"
            v-for="list in menu"
            :key="list.url"
          >
            <ShowListMenu :list="list" :categories="categories">
              <slot></slot>
            </ShowListMenu>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex gap-3 pb-5" v-if="props.user">
      <DropDownUser :user="props.user">
        <MenuItem
          as="template"
          class="block text-lg text-white capitalize font-bold max-w-[200px] overflow-hidden truncate"
        >
          {{ props.user.ten_hien_thi }}
        </MenuItem>

        <MenuItem as="template" class="block text-lg text-gray-700">
          <div
            class="flex gap-2 items-center border border-white rounded text-white p-2"
          >
            <img :src="Wallet" class="h-8 w-8" />
            Số dư:
            <span class="font-bold"
              >{{ formatCurrency(props.user.tien) }}
            </span>
          </div>
        </MenuItem>
        <MenuItem v-for="link in links" :key="link.href" as="template">
          <a
            :href="link.href"
            :class="[
              isActive(link.href) ? 'bg-[#566573] ' : '',
              ' border border-white text-white rounded p-2 text-lg flex items-center gap-1',
            ]"
          >
            <img :src="link.icon" class="h-8 w-8" />

            {{ link.label }}
          </a>
        </MenuItem>
      </DropDownUser>
    </div>
    <div class="flex gap-3 pb-5" v-if="!props.user">
      <button class="border p-2 rounded bg-[#D9D9D9] hover:opacity-85">
        <a
          @click="toggleLogin()"
          class="text-black whitespace-nowrap text-lg font-semibold font-['Inter']"
        >
          Đăng nhập</a
        >
      </button>
    </div>
  </header>

  <ModalComponents :show="showLogin" @close="close()" :topPosition="0">
    <Login v-if="showLogin">
      <slot></slot>
      <div class="text-center text-lg">
        <span class="text-white"> Chưa có tài khoản ? </span>
        <span
          @click="toggleRegister()"
          class="text-[#7DCEA0] font-bold underline cursor-pointer"
        >
          Đăng ký ngay
        </span>
      </div>
    </Login>
  </ModalComponents>

  <!-- auth register -->
  <ModalComponents :show="showRegister" @close="close()" :topPosition="0">
    <Resgiter v-if="showRegister">
      <slot></slot>
      <div class="text-center text-base">
        <span class="text-white"> Chưa có tài khoản ? </span>
        <span
          @click="toggleLogin()"
          class="text-[#B03A2E] font-bold underline cursor-pointer"
        >
          Đăng nhập ngay
        </span>
      </div>
    </Resgiter>
  </ModalComponents>

  <div
    class="flex gap-3 fixed bottom-4 sm:bottom-5 right-2 sm:w-auto ms-auto z-[6]"
    id="cart-item"
  >
    <a
      :href="route('cart')"
      class="flex self-center justify-center px-3 py-2 rounded-full"
    >
      <img
        :src="CartIcon"
        class="h-16 sm:h-20 w-16 sm:w-20 bg-white p-2 rounded-full"
      />
    </a>
  </div>
</template>
