<script setup>
import route from 'ziggy-js'
import Money from '../../components/icon/Money.vue'
</script>
<template>
  <!-- discount -->
  <div
    class="w-full me-3 pb-5 sm:pb-12 border-b border-[#7b7b7c] flex flex-col mt-5 gap-3"
  >
    <div class="text-black text-2xl font-bold font-['Inder']">Mã giảm giá:</div>
    <!-- Discount code form -->
    <div class="flex flex-col">
      <form
        :action="route('discount.check')"
        method="POST"
        enctype="multipart/form-data"
      >
        <slot></slot>
        <div class="w-full mb-3">
          <input
            type="text"
            id="discountCode"
            name="code"
            class="text-black text-xl bg-white w-full font-normal placeholder:text-black border border-gray-400 rounded"
            placeholder="Nhập mã giảm giá"
            required
          />
        </div>

        <div class="md:w-1/2 mb-3">
          <button
            class="bg-[#3A4D80] text-white text-base sm:text-xl font-semibold font-['Inter'] p-2 rounded"
          >
            Áp dụng mã
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="flex items-center flex-wrap pb-2 border-b mt-3">
    <div class="md:w-1/2">
      <label
        for="name"
        class="text-black text-lg sm:text-xl font-semibold font-['Inter']"
        >TỔNG TIỀN:</label
      >
    </div>
    <div class="md:w-1/2 h16-bo-26 flex justify-end ms-auto">
      <label
        for="email"
        class="flex gap-1 text-black text-lg sm:text-xl font-semibold font-['Inter']"
      >
        {{ DisCount(calculateTotalcart(cart), discount) }}
      </label>
    </div>
  </div>
  <div class="flex py-2 flex-wrap border-b">
    <div class="md:w-1/2">
      <label
        for="message"
        class="text-black text-lg sm:text-xl font-semibold font-['Inter']"
        >Số dư hiện tại:</label
      >
    </div>
    <div class="md:w-1/2 flex justify-end ms-auto">
      <label
        for="message"
        class="flex gap-1 text-black text-lg sm:text-xl font-semibold font-['Inter']"
      >
        {{ formatCurrency(user.tien) }}
      </label>
    </div>
  </div>

  <div
    class="flex justify-between flex-wrap pb-10 sm:pb-[105px] border-b border-[#7b7b7c] py-2"
  >
    <div class="md:w-1/2">
      <label
        for="message"
        class="text-black text-lg sm:text-xl font-semibold font-['Inter'] whitespace-nowrap"
        >Số tiền cần nạp thêm:</label
      >
    </div>
    <div class="md:w-1/2 h16-bo-26 flex justify-end ms-auto">
      <label
        for="message"
        class="flex gap-1 text-black text-lg sm:text-xl font-semibold font-['Inter']"
      >
        {{
          calculateTotal(cart, user.tien, discount) <= 0
            ? 0
            : formatCurrency(calculateTotal(cart, user.tien, discount))
        }}</label
      >
    </div>
  </div>
  <!--  -->
  <div
    v-if="calculateTotal(cart, user.tien, discount) > 0"
    class="flex flex-col"
  >
    <div class="col-12 text-lg font-semibold font-['Inter'] text-black my-5">
      BẠN KHÔNG ĐỦ SỐ DƯ ĐỂ THANH TOÁN
    </div>
    <div class="col-12 mt-3">
      <a
        :href="route('recharge')"
        class="btn bg-[#F91106] text-white p-2 rounded-xl"
        style="color: #fff"
      >
        <strong class="text-white text-lg font-semibold font-['Inter']"
          >Nạp Thêm Tiền</strong
        >
      </a>
    </div>
  </div>
  <div v-if="calculateTotal(cart, user.tien, discount) <= 0" class="mt-2">
    <div class="col-12">
      <form
        :action="
          $group == 'service'
            ? route('checkout', { product_type: 'service' })
            : route('checkout', { product_type: 'product' })
        "
        method="POST"
        enctype="multipart/form-data"
      >
        <slot></slot>
        <button
          class="btn bg-[#009818] w-full sm:w-1/2 text-white p-2 rounded-xl text-lg font-semibold font-['Inter']"
          type="submit"
        >
          Thanh toán
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {},
    cart: {},
    group: {},
    discount: {
      type: Array,
      default: 0,
    },
  },
  methods: {
    calculateTotal(cart, moneyUser, discount) {
      const carts = Object.values(cart)
      let total = 0
      carts.forEach((item) => {
        total += item.price * item.quantity
      })

      const finalTotal = total - moneyUser - discount
      if (finalTotal >= 0) {
        return finalTotal
      }
      return 0
    },

    calculateTotalcart(cart) {
      let carts = Object.values(cart)
      let total = 0
      carts.forEach((item) => {
        total += item.price * item.quantity
      })

      return total
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(value)
    },

    DisCount(price, discount) {
      const pricenew = price - discount
      if (pricenew >= 0) {
        return new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(pricenew)
      }
      return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(0)
    },
  },
}
</script>
