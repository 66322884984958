<template>
  <div
    class="tab-pane active mid_layout2 container p-1 sm:p-4"
    id="tabs-1"
    role="tabpanel"
  >
    <div class="box-paiement p-3">
      <div class="flex justify-center flex-wrap sm:flex-nowrap gap-4">
        <div class="w-full sm:w-[60%]">
          <div class="flex justify-center min-h-96">
            <slot></slot>
          </div>
        </div>
        <div class="w-full sm:w-[40%] py-5">
          <div class="flex flex-col gap-3">
            <div class="table-responsive">
              <table class="table table-bordered text-sm">
                <tbody>
                  <tr class="flex flex-wrap gap-2">
                    <td class="text-left text-black text-lg font-normal">
                      Số điện thoại:
                    </td>
                    <td class="text-left">
                      <div class="grid grid-cols-3">
                        <input
                          type="text"
                          class="text-sm text-center rounded-s-lg col-span-2"
                          id="copyInput_sdt"
                          :value="momo.account_number"
                          readonly
                        />
                        <button
                          @click="copyText(momo.account_number)"
                          class="bg-[#3498DB] rounded-e-lg text-black border border-black p-2 text-sm"
                        >
                          Copy
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr class="flex flex-wrap gap-2 pt-5">
                    <td class="text-left text-black text-lg font-normal">
                      Chủ Tài khoản:
                    </td>
                    <td class="text-left">
                      <div class="grid grid-cols-3">
                        <input
                          type="text"
                          class="text-sm text-center rounded-s-lg col-span-2"
                          id="copyInput_sdt"
                          :value="momo.account_name"
                          readonly
                        />
                        <button
                          @click="copyText(momo.account_name)"
                          class="w-auto bg-[#3498DB] rounded-e-lg text-black border border-black p-2 text-sm"
                        >
                          Copy
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="text-left text-black mt-5">
              Nội dung chuyển khoản:

              <div class="row-code">
                <div
                  class="snippet-clipboard-content notranslate position-relative overflow-auto"
                >
                  <div class="grid grid-cols-3">
                    <input
                      type="text"
                      class="text-sm text-center rounded-s-lg col-span-2"
                      id="copyInput"
                      :value="'NAP ' + user.id"
                      readonly
                    />
                    <button
                      @click="copyText('NAP ' + user.id)"
                      class="bg-[#3498DB] rounded-e-lg text-black border border-black p-2 text-sm"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

const $toast = useToast()
$toast.clear()

const props = defineProps(['momo', 'user'])

const copyText = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy)
    $toast.success('Đã sao chép văn bản vào bảng nhớ tạm')
  } catch (err) {
    $toast.error('Đa ', err)
  }
}
</script>
